import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {v4 as uuidv4} from "uuid";
import {DropzoneArea} from 'material-ui-dropzone';


// Material UI Components
import {Alert} from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import {SDCloudBackendAPIWithToken, SDCloudCustomizeAPIWithToken} from "../../../apis/backendAPI";
import {IconButton} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {Api} from "../../../config/api";
import {HIDE_LOADER, SHOW_LOADER} from "../../../action_creators/actioncreator";
import {useDispatch} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomTable from "../../Usermanagement/components/Table";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// Styling
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "50px",
    },
    item: {
        textAlign: "end",
    },
    button: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "40px",
        color: "white",
        border: "none",
        width: "200px",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filter: {
        marginTop: "2%",
        padding: "20px 0px 50px 50px",
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        boxSizing: "border-box",
        boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
        position: "relative",
        width: "80%"
    },
    buttongrid:{
        marginTop: "20px"
    },
    btnStyle: {
        background: `#FFFFFF`,
        height: "40px",
        width: "200px",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filterButton: {
        padding: "8px 50px 0px 0px",
    },
    icon: {
        marginTop: "30px",
        // paddingLeft: "80px",
        // [theme.breakpoints.down("md")]: {
        //   marginTop: "26px",
        //   paddingLeft: "40px",
        // },
    },
}));

const S3upload = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // States
    const [notificationType, setNotificationType] = useState("");
    const [value, setValue] = React.useState("learnings");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setformData] = useState(null);


    var fileData = new FormData;

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        if (formData.has('attachment')){
            formData.set('path', event.target.value)
            setformData(formData)
        }
    };

    const attachfile = (file) => {
        if (file.length > 0) {
            fileData.append('attachment', file[0])
            fileData.append('path', value)
            setformData(fileData)
        } else {
        }
    };
    const upload = () => {
            let payload = formData
            setIsLoading(true)
            SDCloudCustomizeAPIWithToken()
                .post(
                    Api.s3Upload, payload, {
                        headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("user")}`
                        }
                    }
                )
                .then(response => {
                    setIsLoading(false)
                    setShowNotification(true);
                    setNotificationMessage('Upload successful URL of the image: '+ response.data.urls[0]);
                    setNotificationType("success");
                }).catch(err => {
                //console.log(err.response);
                setIsLoading(false)
                let message = err.response.data.message;
                setShowNotification(true);
                setNotificationMessage(message);
                setNotificationType("error");
            });
    }

    return (
        <div>
            <Grid container>
                {showNotification ? (
                    <Alert
                        onClose={() => setShowNotification(false)}
                        severity={notificationType}
                        style={{
                            width: "80%",
                            borderRadius: "0.5rem",
                            marginBottom: "-0.5rem",
                          }}
                    >
                        {notificationMessage}
                    </Alert>
                ) : null}
                <Grid container className={classes.filter}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <Grid container className={classes.gridContainer}>
                                <Grid item xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                >
                                    <Typography variant="h4">S3upload</Typography>
                                </Grid>
                                <br/>
                                <p>Drag and drop your files here</p>
                                <br/><br/>
                                <Grid item xs={12} md={12}>
                                    <Grid container justify="space-around">
                                        <Grid item xs={12} md={10}>
                                            <Grid item md={12}>
                                                <Grid container justify="space-between">
                                                    <Grid item xs={12} md={12}>
                                                        {isLoading ? <LoaderHoc isLoading={isLoading}></LoaderHoc>:
                                                        (<DropzoneArea
                                                            acceptedFiles={['image/*']}
                                                            dropzoneText={".SVG .JPG .PNG "}
                                                            showPreviews={false}
                                                            onChange={(files) => attachfile(files)}
                                                            name="files"
                                                            filesLimit={1}
                                                        />)}
                                                    </Grid>
                                                    <Grid item xs={12} md={12} className={classes.buttongrid}>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup
                                                                row
                                                                aria-label="ip_type"
                                                                name="ip_type"
                                                                value={value}
                                                                onChange={handleRadioChange}
                                                                className={classes.radioButton}
                                                            >
                                                                <FormControlLabel
                                                                    value="learnings"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Learning Center"
                                                                />
                                                                <FormControlLabel
                                                                    value="salesdemo"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Showcases"
                                                                />
                                                                <FormControlLabel
                                                                    value="sandboxes"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Sandboxes"
                                                                />
                                                                <FormControlLabel
                                                                    value="designer_objects"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Design Center"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container alignItems={'center'} justify={'space-evenly'} className={classes.buttongrid}>
                                                        <Grid item xs={12} md = {5} spacing={1}>
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                disableElevation
                                                                className={classes.btnStyle}
                                                                size="large"
                                                                fullWidth
                                                                disableFocusRipple
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} md = {3} spacing={1}>
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                disableElevation
                                                                size="large"
                                                                fullWidth
                                                                disableFocusRipple
                                                                onClick={upload}
                                                                className={classes.button+' '+classes.buttOutlined}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default S3upload;
