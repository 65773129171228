import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Formik
import { useFormi, useFormik } from "formik";

// Material UI Components
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { resetPassword } from "../../../apis/APIServices";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { useHistory } from "react-router-dom";
import {HIDE_LOADER, SHOW_LOADER} from "../../../action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { useDispatch } from "react-redux";

// Local
import ProfileTable from "./ProfileTable";
import LoaderHoc from "components/HOC/LoaderHoc";

const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const formControlProps = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px 30px 30px 45px",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    background: "#FFFFFF",
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    // minHeight: "334px",
    position: "relative",
    marginTop: "15px",
  },
  body2: {
    color: "#838383",
    paddingTop: "10px",
    fontSize: "12px",
  },
  gridContainer: {
    padding: "0 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

const ProfileDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const [menuItems, setMenuItems] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [learningsData, setLearningsData] = useState([]);
  const [sandboxesData, setSandboxesData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Template Name",
      accessor: "template_name",
    },
    {
      Header: "Name",
      accessor: "display_name",
    },
    // {
    //   Header: "Can View",
    //   accessor: "can_view",
    // },
    {
      Header: "Permission",
      accessor: "permission",
    },
    {
      Header: "Lab Hours",
      accessor: "lab_hours",
    },
    {
      Header: "Allocate Hours",
      accessor: "allocate_hours",
    },
  ];
  const handlePrevious = () => {
    history.push(
      {
        pathname:"/instructer/addtraining",
        state: {
          training_name : history.location.state.training_name,
          training_email: history.location.state.training_email,
          time_zone : history.location.state.time_zone,
          start_date: history.location.state.start_date,
          end_date: history.location.state.end_date,
          no_of_participants: history.location.state.no_of_participants,
          description: history.location.state.description,
        },
      });
  };
  const formik = useFormik({
    initialValues: {
      deal_id: "",
      learningPermissions: [],
      learningViews: [],
      sandboxPermissions: [],
      sandboxViews: [],
    },
    onSubmit: (values) => {
      let permissions = [];

      values.learningViews.forEach((item) => {
        let payload = {
          resource_type: "Learning",
          resource_name: item,
          permission: "view",
          alloc_hours: 0,
        };
        if (!values.learningPermissions.includes(item)) {
          permissions.push(payload);
        }
      });

      values.learningPermissions.forEach((item) => {
        let payload = {
          resource_type: "Learning",
          resource_name: item,
          permission: "create",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };
        permissions.push(payload);
      });

      values.sandboxViews.forEach((item) => {
        let payload = {
          resource_type: "Sandbox",
          resource_name: item,
          permission: "view",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };
        if (!values.sandboxPermissions.includes(item)) {
          permissions.push(payload);
        }
      });

      values.sandboxPermissions.forEach((item) => {
        let payload = {
          resource_type: "Sandbox",
          resource_name: item,
          permission: "create",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };
        permissions.push(payload);
      });
      let payload = {
        deal_id: values.deal_id,
        learning: learningsData,
        sandbox: sandboxesData,
        training_name : history.location.state.training_name,
        time_zone : history.location.state.time_zone,
        start_date: history.location.state.start_date,
        end_date: history.location.state.end_date,
        upload_file: history.location.state.upload_file,
        no_of_participants: history.location.state.no_of_participants,
        permissions: permissions,
        description: history.location.state.description,
      };
      dispatch(SHOW_LOADER());
      SDCloudBackendAPIWithToken()
        .post("/instructer/add_new_training/", payload)
        .then((resp) => resp.data)
        .then((data) => {
          let referenceId = data.context.reference_id;
          let range = history.location.state.no_of_participants;
          for(let i=1; i<= range; i++){
            let username = "user" + String(i) + "_" + String(referenceId);
            let profile_name = String(values.deal_id)+'-'+ String(referenceId) + "_" + "user" + String(i);
            let email = history.location.state.training_email;
            let payload = {
              profile_name: profile_name,
              permissions: permissions,
            };
            SDCloudBackendAPIWithToken()
            .post("/create_profile", payload)
            .then((resp) => resp.data)
            .then((data) => {
              payload = {
                username: username,
                email: email,
                privilege: "developer",
                permission_profile_name: profile_name,
              };
        
              SDCloudBackendAPIWithToken()
                .post("/create_user", payload)
                .then((resp) => resp.data)
                .then((data) => {
                  let reset_id = data.reset_id;
                  payload = {
                    "username": username,
                    "password": username,
                    "email": email,
                    "access_token": "",
                    "reset_id": reset_id
                  }
                  SDCloudBackendAPIWithToken()
                  .post("/reset_password", payload)
                  .then((resp) => resp.data)
                  .then((data) => {
                      dispatch(HIDE_LOADER());
                      const payload = {
                        message: "Training Created Successfully.",
                        color: "success",
                      };
                      dispatch(SHOW_NOTIFICATION({ payload }));
                  })
                  .catch((err) => {
                    dispatch(HIDE_LOADER());
                    let message = err.response.data.message;
                    const payload = {
                      message: err?.response?.data?.message || "Password Reset Failed",
                      color: "danger",
                      error: err
                    };
                    payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
                  });
                  history.push("/usermanagement/instructer/viewtraining");
                })
                .catch((err) => {
                  dispatch(HIDE_LOADER());
                  let message = err.response.data.message;
                  const payload = {
                    message: err?.response?.data?.message || "User Creation Failed",
                    color: "danger",
                    error: err
                  };
                  payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
                });
            })
            .catch((err) => {
              dispatch(HIDE_LOADER());
              let message = err.response.data.message;
              const payload = {
                message: err?.response?.data?.message || "Profile Creation Failed",
                color: "danger",
                error: err
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            });
          }
        })
        .catch((err) => {
          dispatch(HIDE_LOADER());
          let message = err.response.data.message;
          const payload = {
            message: err?.response?.data?.message || "Unable to create New Training",
            color: "danger",
            error: err
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));          
        });
    },
    validate: (values) => {
      let errors = {};

      if (!values.deal_id) {
        //console.log("deal id error");
        errors.deal_id = "Required";
      }
      return errors;
    },
  });

  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE("New Training"));
    renderTemplateData();
  }, []);

  const renderTemplateData = async () => {
    const templates = SDCloudBackendAPIWithToken()
      .get("/get_resources")
      .then((resp) => resp.data)
      .then((data) => {
        let learnings = [];
        let sandboxes = [];

        // Learnings
        data.learnings.forEach((item) => {
            learnings.push({
              template_name: item.name,
              display_name: item.display_name,
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              hours_available:
                item.allocated_hours - item.consumed_hours > 0
                  ? item.allocated_hours - item.consumed_hours
                  : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  inputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
            });
        });

        // Sandboxes
        data.sandboxes.forEach((item) => {
            if(item.name === "designer_portal"){
              return;
            }
            sandboxes.push({
              template_name: item.name,
              display_name:
                item.name === "designer_portal"
                  ? "Designer"
                  : item.display_name,
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              hours_available:
                item.allocated_hours - item.consumed_hours > 0
                  ? item.allocated_hours - item.consumed_hours
                  : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  InputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
            });
        });

        setLearningsData(learnings);
        setSandboxesData(sandboxes);
        setShowLoader(false);
      });
  };
  const renderDealIdDropdown = async () => {
    const profileItems = SDCloudBackendAPIWithToken()
      .get("/get_engagement_ids")
      .then((resp) => resp.data)
      .then((data) => {
        let engagements = data.engagements_id_list;

        let items = engagements.map((item) => {
          return (
            <option key={uuidv4()} value={item}>
              {item}
            </option>
          );
        });

        items = [
          <option key={uuidv4()} value={null} selected>
            None
          </option>,
          ...items,
        ];

        setMenuItems(items);
      });
  };

  useEffect(() => {
    renderDealIdDropdown();
    renderTemplateData();
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h4">Lab Allocation</Typography>
            <Typography variant="body2" className={classes.body2}>
              Fill all the required details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="demo-simple-select-helper-label">
                Select Deal ID
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                labelWidth={100}
                fullWidth
                name="deal_id"
                value={formik.values.deal_id}
                onChange={formik.handleChange}
                native
                error={formik.errors.deal_id ? true : false}
                helperText={formik.errors.deal_id}
              >
                {menuItems.length > 0 ? (
                  menuItems
                ) : (
                  <option value="">None</option>
                )}
              </Select>
            </FormControl>
          {showLoader ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
              }}
            >
              <LoaderHoc isLoading={showLoader}></LoaderHoc>
            </div>
          ) : (
            <>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={learningsData}
                    tableTitle="Learning Labs"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={sandboxesData}
                    tableTitle="Sandboxes"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name", "lab_hours"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={8}></Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth style={{width:"90%"}}>
                  <Button
                    onClick={handlePrevious}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disableElevation
                    disableFocusRipple
                    type="submit"
                    
                  >
                    Previous
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disableElevation
                    disableFocusRipple
                    type="submit"
                  >
                    Create
                  </Button>
                </FormControl>
              </Grid>
            </>
          )}
          </Grid>
      </form>
    </div>
  );
};

export default ProfileDetails;
