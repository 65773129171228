import React, { useEffect } from 'react';

const RedirectNetbox = () => {
  useEffect(() => {
    // Redirect to an external site
    // window.location.href = 'http://ec2-54-190-81-221.us-west-2.compute.amazonaws.com/';
    window.open('http://ec2-54-190-81-221.us-west-2.compute.amazonaws.com/')
  }, []);

  return null; // You can return a loading spinner or message while redirecting
};

export default RedirectNetbox;