import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  Button,
  Checkbox,
  TextField
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { LOADER_TYPE, SECONDARY_COLOR } from "global_constants/app_constants";
import GridItem from "components/Grid/GridItem";
import CustomModal from "../../../components/CustomModal";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import theme from "theme";
import FormControl from "@material-ui/core/FormControl";
const useStyles = makeStyles(styles);

const CreateTemplateModal = ({
  visible,
  onClose,
  onCreateTemplate,
  is_multi_vm,
  actionWizard,
  cloud_native_enabled
}) => {
  const [details, setDetails] = useState({
    templateName: "",
    isLiveEditing: false
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isMultiVm, setIsMultiVm] = useState(false);
  const [value, setValue] = useState("singleVM");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [cluster, setCluster] = useState("networking");
  const classes = useStyles();
  const clusterFlavors = Object.keys(JSON.parse(localStorage.getItem("machine_types")));
  const liveEditEnabled = JSON.parse(localStorage.getItem("profileData")).liveedit_enabled;
  const [flavor,setFlavor] = useState(clusterFlavors[0])
  const handleRadioChange = event => {
    setValue(event.target.value);
    if (event.target.value == "multiVM") {
      setIsMultiVm(true)
      setDetails({
        ...details,
        isMultiVM: "true"
      });
    } else {
      delete details.isMultiVM;
    }
  };
  const handleFlavorChange = event =>{
    setFlavor(event.target.value)
    setShowNotification(false);
    if(event.target.value !== "Standard"){
      //console.log(event.target.value)
      setShowNotification(true);
      setNotificationType("warning");
      setNotificationMessage("By default, the lowest cost flavor is set. If you switch, it will cost more.");
    }
  }
  const handleClusterChange = e => {
    const { name, value, checked, type } = e.target;
    setCluster(e.target.value);
  };

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setDetails({
      ...details,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const onSubmit = () => {
    if (isMultiVm == false){
      var profileData = JSON.parse(localStorage.getItem('profileData'));
      profileData.multi_vm = false;

      localStorage.setItem('profileData', JSON.stringify(profileData));
    }
    var re = /^[a-zA-Z0-9-_]+$/;
    let multivm = JSON.parse(localStorage.getItem("profileData")).multi_vm
    if (details.templateName && re.test(details.templateName) && details.templateName.length > 3 && JSON.parse(localStorage.getItem("profileData")).privilege != "admin") {
      setBtnDisabled(true);
      if (details.isMultiVM) {
        onCreateTemplate(details.templateName, null, details.isMultiVM);
      } else {
        onCreateTemplate(details.templateName,null,details.isMultiVM,details.isLiveEditing, cluster, flavor);
      }
    } else{
      setShowNotification(true);
      setNotificationType("error");
      if(JSON.parse(localStorage.getItem("profileData")).privilege === "admin"){
        setNotificationMessage('Admin can not create a template');
      }else{
      setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
    }
  };

  return (
    <CustomModal
      title="Enter template name"
      visible={visible}
      onClose={onClose}
      className="new-template-modal"
    >
      <GridItem className="content" xs={12} sm={12} md={12} lg={12}>
        <TextField
          type="text"
          variant="filled"
          name="templateName"
          fullWidth
          value={details.templateName || ""}
          onChange={handleChange}
        />
        {cloud_native_enabled && actionWizard==''?(<>
          <div className="margin-radioOnLabel">
            <span className="color-grey">Select cluster type</span>
          </div>
          <RadioGroup
              row
              aria-label="position"
              name="cluster"
              value={cluster}
              onChange={handleClusterChange}
          >
            <FormControlLabel
                control={<Radio color="primary"/>}
                label="Networking"
                value="networking"
                title="Networking topology consists of networking elements such as controllers, routers and switches"
            />
          <FormControlLabel
                control={<Radio color="primary"/>}
                label="Cloud Native"
                value="cloudNative"
                title="Cloud Native consists of Devices such as Docker, Kubernetes and switches"
            />
          </RadioGroup>
        </>):null}
        {cluster == 'networking' && is_multi_vm ? (
          <>
            <div className="margin-radioOnLabel">
              <span className="color-grey">Select cluster size</span>
            </div>
            <RadioGroup
              row
              aria-label="position"
              name="clusterType"
              value={value}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                control={<Radio color="primary"/>}
                label="Small Topology"
                title="If < 30 devices select Small Topology"
                value="singleVM"
              />
              {actionWizard==''? (<FormControlLabel
                control={<Radio color="primary"/>}
                label="Large Topology"
                title="If > 30 devices select Large Topology"
                value="multiVM"
              />): null}
            </RadioGroup>
          </>
        ) : null}
           {cluster == 'networking' && value=='singleVM' && !actionWizard && (clusterFlavors.length>0)? (
          <>
            <div className="margin-radioOnLabel">
              <span className="color-grey">Select cluster flavor</span>
            </div>
            <RadioGroup
              row
              aria-label="position"
              name="flavor"
              value={flavor}
              onChange={handleFlavorChange}
            >
             {clusterFlavors.map((flavorOption) => (
              <FormControlLabel
                key={flavorOption}
                control={<Radio color="primary" />}
                label={flavorOption}
                value={flavorOption}
              />
            ))}
            </RadioGroup>
          </>
        ) : null}
        {cluster == 'networking' && value=='singleVM' && liveEditEnabled &&
        <div className="islive-checkbox">
          <FormControlLabel
            title="The Live edit feature allows the user to make changes to the active topology"
            control={
              <Checkbox
                checked={details.isLiveEditing}
                name="isLiveEditing"
                className="checkmark"
                onClick={handleChange}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            label="Live editing"
          />
        </div>}
        {!btnDisabled ? (
            <>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            onClick={onSubmit}
            disableFocusRipple
            className={`create-btn ${classes.backColor}`}
          >
            Create template
          </Button>
            </>
        ) : null}
        {showNotification ? (
            <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
            >
              {notificationMessage}
            </Alert>
        ) : null}
        {btnDisabled ? (
          <Loader
            type={LOADER_TYPE}
            color={theme.palette.secondary.main}
            width={50}
            height={50}
            style={{ textAlign: "center" }}
          />
        ) : null}
      </GridItem>
    </CustomModal>
  );
};

export default withRouter(CreateTemplateModal);