import Dashboard from "views/Dashboard";
import Design from "views/Design";

import DashboardIcon from "@material-ui/icons/Dashboard";
import Stats from "@material-ui/icons/EqualizerTwoTone";
import Service from "@material-ui/icons/LocalOfferTwoTone";

import LoginPage from "views/Pages/LoginPage";
import LearningLab from "views/ServiceOffering/LearningLab/LearningLab";
import RedirectNetbox from "views/Netbox/netbox"
import Settings from "./setting";
import Accounts from "views/Accounts/Accounts";
import CreateAccount from "views/Accounts/Create-Account";
import AccountSettings from "views/Accounts/AccountSettings";
import AccountInventory from "views/Accounts/AccountInventory";
import InventoryEdit from "views/Accounts/InventoryEdit";
import CreateUser from "views/Usermanagement/components/CreateUser";
import EditUser from "views/Usermanagement/components/EditUser";
import Reports from "views/Reports/reports";
//import Instructor from "views/Usermanagement/Instructor/InstructorDashboard";
import ViewTraining from "views/Usermanagement/Instructor/ViewTraining";
import AddTraining from "views/Usermanagement/Instructor/AddTraining";
import ProfileDetails from "views/Usermanagement/Instructor/ProfileDetails";

import LaunchLabs from "views/ServiceOffering/LearningLab/LaunchLabs";
import MyProfile from "views/MyProfile/MyProfile";
import Usermanagement from "views/Usermanagement/Usermanagement";
import Contentmanagement from "views/Contentmanagement/Contentmanagement";
import Statistics from "views/Statistics/Statistics";
import SecurityQuestions from "views/Pages/recoverPassword/securityQuestions";
import AccessLabLandingPage from "views/ServiceOffering/LearningLab/acess labs/AccessLabLandingPage";
import ExperienceCenter from "views/ServiceOffering/ExperienceCenter/ExperienceCenter";
import ExperienceCenterShowcases from "views/ServiceOffering/ExperienceCenter/ExperienceCenter-Showcases";
import ExperienceCenterSandboxes from "views/ServiceOffering/ExperienceCenter/ExperienceCenter-Sandboxes";
import AccessSales from "views/ServiceOffering/ExperienceCenter/AccessSales";
import InventoryManagement from "views/InventoryManagement/InventoryManagement";
import LaunchDemo from "./views/ServiceOffering/ExperienceCenter/LaunchDemo";
import AccessSandbox from "./views/ServiceOffering/ExperienceCenter/Sandboxes/AccessSandbox/AccessSandbox";
import SLCDashboard from "./views/ServiceLifeCycle/dashboard/Dashboard";
import MyCustomElements from "./views/ServiceLifeCycle/dashboard/MyCustomElements";
import CreateNodeWizard from "./views/ServiceLifeCycle/wizards/CreateNodeWizard";
import UploaderComponent from "./views/ServiceLifeCycle/views/UploadImage";
import ProductContentSandboxes from "./views/ProductContent/ProductContentSandboxes/ProductContentSandboxes";


import ProductContentShowcases from "./views/ProductContent/ProductContentShowcases/ProductContentShowcases";
import ShowcaseCreate from "./views/ProductContent/ProductContentShowcases/ShowcaseCreate";
import CheatSheetsCreate from "./views/ProductContent/ProductContentShowcases/CheatSheetsCreate";
import IntroductionCreate from "./views/ProductContent/ProductContentShowcases/IntroductionCreate";
import UseCaseCreate from "./views/ProductContent/ProductContentShowcases/UseCaseCreate";
import ProductContent from "./views/ProductContent/ProductContent";
import AddNewSandbox from "./views/ProductContent/ProductContentSandboxes/AddNewSandbox";
import ProductContentSandboxesMenuItems from "./views/ProductContent/ProductContentSandboxes/ProductContentSandboxesMenuItems";
import ProductContentLearningsMenuItems from "./views/ProductContent/ProductContentLearnings/ProductContentLearningsMenuItems";
import ProductContentShowcaseMenuItems from "./views/ProductContent/ProductContentShowcases/ProductContentShowcasesMenuItems";
import SandboxOptionsCreate from "./views/ProductContent/ProductContentSandboxes/SandboxOptionsCreate";
import SandboxDetailsCreate from "./views/ProductContent/ProductContentSandboxes/SandboxDetailsCreate";
import SandboxesCheatSheetsCreate from "./views/ProductContent/ProductContentSandboxes/SandboxesCheatSheetsCreate";
import SandboxesLabsCreate from "./views/ProductContent/ProductContentSandboxes/SandboxesLabsCreate";
import SandboxesCategoriesCreate from "./views/ProductContent/ProductContentSandboxes/SandboxesCategoriesCreate";
import SandboxesExercisesCreate from "./views/ProductContent/ProductContentSandboxes/SandboxesExercisesCreate";
import SandboxExercisesList from "./views/ProductContent/ProductContentSandboxes/SandboxExercisesList";
import ProductContentLearning from "./views/ProductContent/ProductContentLearnings/ProductContentLearning";
import LearningCreate from "./views/ProductContent/ProductContentLearnings/LearningCreate";
import LearningOverviewCreate from "./views/ProductContent/ProductContentLearnings/LearningOverviewCreate";
import LearningModulesCreate from "./views/ProductContent/ProductContentLearnings/LearningModulesCreate";
import CourseOverviewCreate from "./views/ProductContent/ProductContentLearnings/CourseOverviewCreate";
import CourseELearningCreate from "./views/ProductContent/ProductContentLearnings/CourseELearningCreate";
import LearningLabCreate from "./views/ProductContent/ProductContentLearnings/LearningLabCreate";
import LearningCategoryCreate from "./views/ProductContent/ProductContentLearnings/LearningCategoryCreate";
import LearningExercisesCreate from "./views/ProductContent/ProductContentLearnings/LearningExercisesCreate";
import LearningExercisesList from "./views/ProductContent/ProductContentLearnings/LearningExercisesList";


// sidebar svg
import { ReactComponent as usermanagementSvg } from "assets/criterion_networks/svg/usermanagement.svg";
import { ReactComponent as content } from "assets/criterion_networks/svg/content.svg";
import { ReactComponent as homeSvg } from "assets/criterion_networks/svg/dashboardIcon.svg";
import { ReactComponent as learningLabSvg } from "assets/criterion_networks/svg/LearningCenter.svg";
import { ReactComponent as salesDemoSvg } from "assets/criterion_networks/img/sales_demo.png";
import { ReactComponent as experienceSvg } from "assets/criterion_networks/svg/experience.svg";
import { ReactComponent as designSvg } from "assets/criterion_networks/svg/design.svg";
import { ReactComponent as statsSvg } from "assets/criterion_networks/svg/stats.svg";
import { ReactComponent as settingsSvg } from "assets/criterion_networks/svg/settings.svg";
import { ReactComponent as instructorSvg } from "assets/criterion_networks/svg/Instructor.svg";
import { ReactComponent as inventorySvg } from "assets/criterion_networks/svg/white_chip.svg";
import { ReactComponent as netboxSvg } from "assets/criterion_networks/svg/netbox.svg";

import LearnMore from "./views/LearnMore";
import ImportConfiguration from "../src/views/ImportConfig/ImportConfiguration";
// const usermanagementSvg = require("assets/criterion_networks/svg/usermanagement.svg");
import Orchestration from "views/GcpOrchestration/orchestration";

var dashRoutes = [
  {
    path: "/import-configuration",
    name: "import-configuration",
    mini: Service,
    view: false,
    component: ImportConfiguration,
    layout: "/",
  },
  {
    path: "/dashboard",
    name: "Home",
    icon: homeSvg,
    view: true, // decides whether to show in sidebar or not.
    component: Dashboard,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/login-page",
    name: "login",
    icon: usermanagementSvg,
    view: false,
    component: LoginPage,
    layout: "/auth",
    bottomPanel: false,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: usermanagementSvg,
    view: false,
    component: SecurityQuestions,
    layout: "/auth",
    bottomPanel: false,
  },
  {
    path: "/learn-more",
    name: "Learn more",
    mini: Service,
    view: false,
    component: LearnMore,
    layout: "/",
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/design-center",
    name: "Design",
    icon: designSvg,
    view: true,
    component: Design,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/experience-center",
    name: "Experience",
    icon: experienceSvg,
    view: true,
    component: ExperienceCenter,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/experience-center-showcases",
    name: "Experience",
    icon: experienceSvg,
    view: false,
    component: ExperienceCenterShowcases,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/experience-center-sandboxes",
    name: "Experience",
    icon: experienceSvg,
    view: false,
    component: ExperienceCenterSandboxes,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/learning-lab",
    name: "Learning",
    icon: learningLabSvg,
    view: true,
    component: LearningLab,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/criterion-netbox",
    // path: "/",
    name: "Criterion Netbox",
    icon: designSvg,
    view: true,
    component: RedirectNetbox,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },

  // {
  //   path: "/accounts",
  //   name: "Accounts",
  //   icon: statsSvg,
  //   view: true,
  //   component: Accounts,
  //   layout: "/",
  //   bottomPanel: true,
  //   privileges: ["admin", "profile_admin", "developer"]
  // },
  {
    path: "/usermanagement",
    name: "User management",
    icon: usermanagementSvg,
    view: true,
    component: Usermanagement,
    layout: "/",
    bottomPanel: true,
    privileges: ["admin", "profile_admin", "super_admin"],
  },
  {
    path: "/createuser",
    name: "Create User",
    view: false,
    component: CreateUser,
    layout: "/",
    privileges: ["admin", "profile_admin", "super_admin"],
  },
  {
    path: "/edituser",
    name: "Create User",
    view: false,
    component: EditUser,
    layout: "/",
    privileges: ["admin", "profile_admin", "super_admin"],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: statsSvg,
    view: true,
    component: Reports,
    layout: "/",
    bottomPanel: true,
    privileges: ["admin", "profile_admin", "super_admin"],
  },
  {
    path: "/settings",
    name: "Account Admin",
    icon: settingsSvg,
    view: true,
    component: Settings,
    layout: "/",
    bottomPanel: true,
    privileges: ["admin", "profile_admin", "super_admin"],
  },
  // {
  //   path: "/instructor",
  //   name: "Instructor",
  //   icon: instructorSvg,
  //   view: true,
  //   component: Instructor,
  //   layout: "/",
  //   bottomPanel: true,
  //   privileges: ["admin", "profile_admin"],
  // },
  // {
  //   path: "/instructer/viewtraining",
  //   name: "Instructor",
  //   icon: instructorSvg,
  //   view: true,
  //   component: ViewTraining,
  //   mini: Service,
  //   layout: "/",
  //   bottomPanel: true,
  //   privileges: ["admin", "profile_admin"],
  // },

  {
    path: "/instructer/addtraining",
    name: "New Training",
    view: false,
    component: AddTraining,
    mini: Service,
    layout: "/",
  },
  {
    path: "/ProductContentSandboxes/AddNewSandbox",
    name: "New Sandbox",
    view: false,
    component: AddNewSandbox,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/ProductContentSandboxesMenuItems",
    name: "New Sandbox",
    view: false,
    component: ProductContentSandboxesMenuItems,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentLearnings/ProductContentLearningsMenuItems",
    name: "New Sandbox",
    view: false,
    component: ProductContentLearningsMenuItems,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentShowcases/ProductContentShowcasesMenuItems",
    name: "New Sandbox",
    view: false,
    component: ProductContentShowcaseMenuItems,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxOptionsCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxOptionsCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxDetailsCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxDetailsCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxesCheatSheetsCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxesCheatSheetsCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxesLabsCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxesLabsCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxesCategoriesCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxesCategoriesCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxesExercisesCreate",
    name: "New Sandbox",
    view: false,
    component: SandboxesExercisesCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentSandboxes/SandboxExercisesList",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: SandboxExercisesList,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/instructer/profiledetails",
    name: "New Training",
    view: false,
    component: ProfileDetails,
    mini: Service,
    layout: "/",
  },
  {
    path: "/statistics",
    name: "Statistics",
    icon: statsSvg,
    view: true,
    component: Statistics,
    layout: "/",
    bottomPanel: true,
    privileges: ["admin", "profile_admin", "developer", "super_admin"],
  },
  {
    path: "/contentmanagement",
    name: "Content Management",
    icon: content,
    view: true,
    component: Contentmanagement,
    layout: "/",
    bottomPanel: true,
    privileges: ["is_ui_admin", "admin"],
  },
  {
    path: "/inventorymanagement",
    name: "Inventory Management",
    icon: instructorSvg,
    view: true,
    component: InventoryManagement,
    layout: "/",
    bottomPanel: true,
    privileges: [],
  },

  {
    path: "/launch-learning-lab",
    name: "Learning Lab",
    mini: Service,
    view: false,
    component: LaunchLabs,
    layout: "/",
  },
  {
    path: "/access-lab",
    name: "lab",
    mini: Service,
    view: false,
    component: AccessLabLandingPage,
    layout: "/",
  },
  {
    path: "/launch-sales-demo",
    name: "Salesdemo",
    mini: Service,
    view: false,
    component: LaunchDemo,
    layout: "/",
  },
  {
    path: "/launch-poc-demo",
    name: "Pocdemo",
    mini: Service,
    view: false,
    component: LaunchDemo,
    layout: "/",
  },
  {
    path: "/access-salesdemo",
    name: "Salesdemo",
    mini: Service,
    view: false,
    component: AccessLabLandingPage,
    layout: "/",
  },
  {
    path: "/access-sandbox/:uuid/:identifier/",
    name: "accessSandbox",
    mini: Service,
    view: false,
    component: AccessSandbox,
    layout: "/",
  },
  {
    path: "/access-sandbox/:uuid/:identifier/",
    name: "accessSandbox",
    mini: Service,
    view: false,
    component: AccessSandbox,
    layout: "/",
  },
  {
    path: "/createaccount",
    name: "Create Account",
    view: false,
    mini: Service,
    component: CreateAccount,
    layout: "/",
  },
  {
    path: "/accountsettings",
    name: "Settings",
    view: false,
    mini: Service,
    component: AccountSettings,
    layout: "/",
  },
  {
    path: "/accountinventory",
    name: "Inventory",
    view: false,
    mini: Service,
    component: AccountInventory,
    layout: "/",
  },
  {
    path: "/inventoryedit",
    name: "Inventory",
    view: false,
    mini: Service,
    component: InventoryEdit,
    layout: "/",
  },

  
  {
    path: "/product-admin",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: ProductContent,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/product-admin-sandboxes",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: ProductContentSandboxes,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/product-admin-learnings",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: ProductContentLearning,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningOverviewCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningOverviewCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningModulesCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningModulesCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/CourseOverviewCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: CourseOverviewCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/CourseELearningCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: CourseELearningCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningLabCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningLabCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningCategoryCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningCategoryCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningExercisesCreate",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningExercisesCreate,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentLearnings/LearningExercisesList",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: LearningExercisesList,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/product-admin-showcases",
    name: "Product Content",
    icon: experienceSvg,
    view: false,
    component: ProductContentShowcases,
    layout: "/",
    bottomPanel: false,
    privileges: ["admin", "profile_admin", "developer","super_admin"]
  },
  {
    path: "/ProductContentShowcases/CheatSheetsCreate",
    name: "New CheatSheetsCreate",
    view: false,
    component: CheatSheetsCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentShowcases/IntroductionCreate",
    name: "New IntroductionCreate",
    view: false,
    component: IntroductionCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentShowcases/ShowcaseCreate",
    name: "New ShowcaseCreate",
    view: false,
    component: ShowcaseCreate,
    mini: Service,
    layout: "/"
  },
  {
    path: "/ProductContentShowcases/UseCaseCreate",
    name: "New UseCaseCreate",
    view: false,
    component: UseCaseCreate,
    mini: Service,
    layout: "/"
  },

  // {
  //   collapse: true,
  //   name: "Service Offering",
  //   icon: Service,
  //   view: true, // decides whether to show in sidebar or not.
  //   // state names are different for different collapses.
  //   state: "serviceOfferingsCollapse",
  //   views: [
  //     {
  //       path: "/learning-lab",
  //       name: "Learning Lab",
  //       mini: learningLabSvg,
  //       view: true,
  //       component: LearningLab,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/launch-learning-lab",
  //       name: "Learning Lab",
  //       mini: Service,
  //       view: false,
  //       component: LaunchLabs,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/access-lab",
  //       name: "lab",
  //       mini: Service,
  //       view: false,
  //       component: AccessLabLandingPage,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sales-demo",
  //       name: "Sales Demo",
  //       mini: salesDemoSvg,
  //       view: true,
  //       component: SalesDemoLandingPage,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/launch-sales-demo",
  //       name: "Sales Demo",
  //       mini: salesDemoSvg,
  //       view: false,
  //       component: SalesDemoLaunchPage,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/access-sales-demo",
  //       name: "Access Sales Demo",
  //       mini: salesDemoSvg,
  //       view: false,
  //       component: AccessSalesDemo,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/poc-library",
  //       name: "POC Library",
  //       mini: pocSvg,
  //       view: true,
  //       component: PoCLibrary,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sales/detail",
  //       name: "SalesDemo",
  //       mini: salesDemoSvg,
  //       view: false,
  //       component: NewSalesDemo,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/poc/detail",
  //       name: "POC Library",
  //       mini: pocSvg,
  //       view: false,
  //       component: NewPocLib,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "Designer",
  //       mini: designerSvg,
  //       view: true,
  //       component: Dashboard,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/statistics",
  //   name: "My Statistics",
  //   icon: Stats,
  //   view: true, // decides whether to show in sidebar or not.
  //   component: Statistics,
  //   layout: "/admin"
  // },
  // {
  //   path: "/activity-logs",
  //   name: "Activity logs",
  //   icon: Activity,
  //   view: true, // decides whether to show in sidebar or not.
  //   component: Activitylog,
  //   layout: "/admin"
  // }
  // {
  //   path: "/schedule-cluster",
  //   name: "My Scheduled Clusters",
  //   icon: Schedule,
  //   view: true, // decides whether to show in sidebar or not.
  //   component: ScheduleCluster,
  //   layout: "/admin"
  // },
  {
    path: "/profile",
    name: "Profile",
    icon: Stats,
    view: false,
    component: MyProfile,
    layout: "/",
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: learningLabSvg,
  //   view: true,
  //   component: Settings,
  //   layout: "/admin",
  //   bottomPanel: true,
  //   privileges: ["admin", "profile_admin"]
  // },
  // {
  //   path: "/reset-page",
  //   name: "User Management",
  //   icon: DashboardIcon,
  //   view: false,
  //   component: ResetPage,
  //   layout: "/admin"
  // },
  // start of SLC routes
  // {
  // collapse: true,
  // name: "Service Life Cycle",
  // icon: DashboardIcon,
  // view: true, // decides whether to show in sidebar or not.
  // // state: "pageCollapse",
  // views: [
  {
    path: "/slc-dashboard",
    name: "SLC Dashboard",
    mini: DashboardIcon,
    view: false,
    component: SLCDashboard,
    layout: "/",
  },
  {
    path: "/custom-elements",
    name: "Custom Elements",
    mini: DashboardIcon,
    view: false,
    component: MyCustomElements,
    layout: "/",
  },
  {
    path: "/create-element",
    name: "Node",
    mini: Service,
    view: false,
    component: CreateNodeWizard,
    layout: "/",
  },


  {
    path: "/upload-image",
    name: "Upload Image",
    mini: DashboardIcon,
    view: true,
    component: UploaderComponent,
    layout: "/",
  },
  {
    path: "/gcp-orchestration",
    name: "gcp-orchestration",
    icon: homeSvg,
    // view: true, // decides whether to show in sidebar or not.
    component: Orchestration,
    layout: "/",
    bottomPanel: false,
    // privileges: ["admin", "profile_admin", "developer", "super_admin"],
    privileges: ["admin"]
  },
  //   ]
  // }
  // end of SLC routes
];
export default dashRoutes;
