import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import closeIcon from "../../assets/criterion_networks/svg/close.svg";
import CloseIcon from "@material-ui/icons/Close";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import "./CustomModal.scss";

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-83px",
    marginLeft: "90px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  closebtnI: {
    fontSize: "3rem",
  },
  CloseIcon: {
    color: "#ffffff ",
    cursor: "pointer",
    marginLeft: "10px",
  },
  pad: {
    paddingLeft:"110px",
  },
});

const CustomModal = props => {
  const { title, className, visible, onClose, children } = props;
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();

  return (
    // custom common modal
    <Modal
      disableAutoFocus
      open={visible}
      onClose={onClose}
      className={`custom-modal  ${className} ${classes.modal}`}
      disableBackdropClick
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={`main-container ${classes.paper}`}
      >
        {title && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h3 className={`title ${classes.font}`}>{title}</h3>
          </GridItem>
        )}
        <GridContainer justify="flex-end" className={classes.pad}>
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {"     "}
            <span className="closebtn" onClick={onClose} >
              <img className="imgsize"src={closeIcon} alt="close" />
            </span>
          </GridItem>{" "}
        </GridContainer>
        {children}
      </GridContainer>
    </Modal>
  );
};

export default CustomModal;
